<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import './main.scss'

export default {
  name: 'App'
}
</script>
