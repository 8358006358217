<template>
  <div>
    <footer>
      <div class="footer">
        <div class="row">
          <div class="col-md-6 text-center">
            <p>nossas redes sociais</p>
            <div class="socials">
              <a
                target="_blank"
                href="https://www.linkedin.com/company/goop-distribuidora-automotiva-ltda/mycompany/?viewAsMember=true"
                ><img
                  src="../assets/imgs/icons/links-resolucao-maior/linkdn.png"
                  alt="Linkedin"
              /></a>
              <a target="_blank" href="https://www.facebook.com/goopautomotiva"
                ><img
                  src="../assets/imgs/icons/links-resolucao-maior/facebook.png"
                  alt="facebook"
              /></a>
              <a
                target="_blank"
                href="https://www.instagram.com/goopautomotiva/"
                ><img
                  src="../assets/imgs/icons/links-resolucao-maior/instagram.png"
                  alt="instagram"
              /></a>
            </div>
          </div>
          <div class="col-md-6 text-center">
            <p style="font-size: 20px" class="margin-top-mobile">
              GOOP Distribuidora Automotiva Ltda. <br />Rua Guaranésia, 756
              <br />02112-001 - Vila Maria, São Paulo/SP, Brasil <br />Telefone:
              +55 11 2634-1788
            </p>
          </div>
        </div>
      </div>
      <div class="copy">
        <p>Copyright © 2021 Goop</p>
      </div>
    </footer>
  </div>
</template>
<style>
@media (max-width: 600px) {
  .margin-top-mobile {
    font-size: 16px !important;
    padding: 20px;
  }
}
</style>
<script>
export default {
  name: "Footer",
};
</script>
