<template>
    <div>
        <!-- NAVBAR MOBILE -->
        <nav class="navbar navbar-expand-lg fixed-top navbar-light hide-md">
            <router-link to="/" class="navbar-brand logo"><img src="../assets/imgs/home/goop_hor_fullcolor_neg_ciano_rgb.png" alt="Goop" class="img-fluid hide-sm"><img src="../assets/imgs/icons/logo.png" alt="Goop" class="img-fluid hide-md"></router-link>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarMb" aria-controls="navbarMb" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <!-- NAVBAR SM- -->
            <div class="collapse navbar-collapse" id="navbarMb">
                <div class="fix-nav">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <router-link to="/Goop" class="nav-link">Goop</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/Produtos" class="nav-link">Produtos</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/Unidades" class="nav-link">Unidades</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/Fale-conosco">Fale conosco</router-link>
                        </li>
                    </ul>
                    <div class="social-menu">
                        <p>nossas redes sociais</p>
                        <ul class="list-inline">
                            <li class="list-inline-item">
                                <a class="nav-socials" target="_blank" href="https://www.linkedin.com/company/goop-distribuidora-automotiva-ltda/mycompany/?viewAsMember=true"><img src="../assets/imgs/icons/linkedin.png" alt="Linkedin"></a>
                            </li>
                            <li class="list-inline-item">
                                <a class="nav-socials" target="_blank" href="https://www.facebook.com/goopautomotiva"><img src="../assets/imgs/icons/facebook.png" alt="Facebook"></a>
                            </li>
                            <li class="list-inline-item">
                                <a class="nav-socials" target="_blank" href="https://www.instagram.com/goopautomotiva/"><img src="../assets/imgs/icons/instagram.png" alt="Instagram"></a>
                            </li>
                        </ul>
                        <p><span><a class="nav-socials" target="_blank" href="https://vempragoop.gupy.io/"><img src="../assets/imgs/icons/trabalhe-conosco.png" alt="Trabalhe conosco" width="15" height="15" style="margin: -2px 5px 0 0"> Trabalhe conosco</a></span></p>
                    </div>
                </div>
            </div>
        </nav>
        <!-- NAV DESKTOP -->
        <nav class="navbar navbar-expand-lg fixed-top navbar-light hide-sm">
            <router-link to="/" class="navbar-brand logo"><img src="../assets/imgs/home/goop_hor_fullcolor_neg_ciano_rgb.png" alt="Goop" class="img-fluid hide-sm"><img src="../assets/imgs/icons/logo.png" alt="Goop" class="img-fluid hide-md"></router-link>
            <!-- NAVBAR MD+ -->
            <div class="collapse navbar-collapse">
                <div class="navbar-collapse fix-nav">
                    <ul class="navbar-nav ml-auto">
                        <li>
                            <a class="nav-socials" target="_blank" href="https://vempragoop.gupy.io/"><img src="../assets/imgs/icons/trabalhe-conosco.png" alt="Trabalhe conosco" width="15" height="15" style="margin: -2px 5px 0 0"> Trabalhe conosco</a>
                        </li>
                        <li>
                            <a class="nav-socials" target="_blank" href="https://www.linkedin.com/company/goop-distribuidora-automotiva-ltda/mycompany/?viewAsMember=true"><img src="../assets/imgs/icons/linkedin.png" alt="Linkedin"></a>
                        </li>
                        <li>
                            <a class="nav-socials" target="_blank" href="https://www.facebook.com/goopautomotiva"><img src="../assets/imgs/icons/facebook.png" alt="Facebook"></a>
                        </li>
                        <li>
                            <a class="nav-socials" target="_blank" href="https://www.instagram.com/goopautomotiva/"><img src="../assets/imgs/icons/instagram.png" alt="Instagram"></a>
                        </li>
                    </ul>
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item">
                            <router-link to="/Goop" class="nav-link">Goop</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/Produtos" class="nav-link">Produtos</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/Unidades" class="nav-link">Unidades</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="btn btn-navbar" to="/Fale-conosco">Fale conosco</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
    export default {
        name: 'Navbar'
    }
</script>