<template>
  <div class="home">
    <Navbar/>
    <!-- BANNER -->
    <div class="banner">
      <div class="container">
        <div class="row">
          <div class="col">
            <h2>Turbinando negócios automotivos</h2>
          </div>
          <div class="clearfix"></div>
          <div class="col-md-9 col-lg-7">
            <h1>Somos a solução completa para<br>manutenção e reposição automotiva</h1>
            <div class="hide-sm">
              <p>Promovemos acesso às ferramentas certas para fazer a diferença: conhecimento, dados, serviços e as melhores peças Takao.</p>
              <router-link to="/goop" class="btn btn-azul">Saiba mais</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hide-md">
      <div class="frase-mb">
        <div class="container">
          <p>Promovemos acesso às ferramentas certas para fazer a diferença: conhecimento, dados, serviços e as melhores peças Takao.</p>
        </div>
      </div>
    </div>
    <section class="goop">
      <div class="container">
        <h1>Prazer somos o Goop</h1>
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/x8Ulj8JNbiU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div class="col-xs-12 col-md-5 offset-md-1">
            <h2>Marca atual</h2>
            <p>Pioneira no mercado de <b>reposição automotiva</b> há 20 anos, a Goop é uma das principais responsáveis pela comercialização de componentes internos de <b>motor</b> para veículos leves, empilhadeiras e utilitários em todo o Brasil.</p>
          </div>
        </div>
      </div>
    </section>
    <div class="blue hide-sm">
      <div class="container">
        <div class="row">
          <div class="col-xs-12">
            <h1>Novos tempos,<br>novas mudanças,<br>nova essência.</h1>
          </div>
        </div>
        <div class="align-btn">
          <router-link to="/goop" class="btn btn-azul">Saiba mais</router-link>
        </div>
      </div>
    </div>
    <div class="blue-mb hide-md">
      <div class="container">
        <h1>Novos tempos,<br>novas mudanças,<br>nova essência.</h1>
        <img src="../assets/imgs/home/goop.png" class="img-fluid">
      </div>
    </div>
    <section class="anos">
      <div class="container hide-sm">
        <div class="row">
          <div class="col-md-12">
            <h1>Desde os anos 2000,</h1>
            <h3>Seguindo uma trajetória de crescimento e determinação.</h3>
            <h5>Clique na bolinha para mudar o ano.</h5>
          </div>
        </div>
        <div class="row justify-content-center" style="margin-top: 40px">
          <div class="col-xs-12 col-md-9">
            <div class="row">
              <div class="col-md-7">
                <ul class="nav justify-content-center timeline timeline-horizontal" id="myTab" role="tablist">
                  <li class="nav-item timeline-item" role="presentation">
                    <p>2000</p>
                    <a class="nav-link timeline-badge active" id="2000-tab" data-toggle="tab" href="#aba1" role="tab" aria-controls="2000" aria-selected="true"></a>
                  </li>
                  <li class="nav-item timeline-item" role="presentation">
                    <p>2008</p>
                    <a class="nav-link timeline-badge" id="2008-tab" data-toggle="tab" href="#aba2" role="tab" aria-controls="2008" aria-selected="false"></a>
                  </li>
                  <li class="nav-item timeline-item" role="presentation">
                    <p>2011</p>
                    <a class="nav-link timeline-badge" id="2011-tab" data-toggle="tab" href="#aba3" role="tab" aria-controls="2011" aria-selected="false"></a>
                  </li>
                  <li class="nav-item timeline-item" role="presentation">
                    <p>2019</p>
                    <a class="nav-link timeline-badge" id="2019-tab" data-toggle="tab" href="#aba4" role="tab" aria-controls="2019" aria-selected="false"></a>
                  </li>
                  <li class="nav-item timeline-item" role="presentation">
                    <p>2021</p>
                    <a class="nav-link timeline-badge" id="2021-tab" data-toggle="tab" href="#aba5" role="tab" aria-controls="2021" aria-selected="false"></a>
                  </li>
                </ul>
              </div>
              <div class="col-md-5">
                <div class="compl-timeline">
                  O que nos move vem do passado e direciona nosso futuro.
                </div>
              </div>
              <div class="col-md-12">
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" id="aba1" role="tabpanel" aria-labelledby="2000-tab">
                    <h2>2000 - Nasce a Goop</h2>
                    <p class="aba">Nasce a Goop, fundada pela Fátima Soares a empresa surge em uma pequena sala dentro da retífica MM no bairro da Vila Guilherme, Zona Norte de São Paulo, aos poucos a empresa cresce e no ano seguinte muda para o bairro da Vila Maria.</p>
                  </div>
                  <div class="tab-pane fade" id="aba2" role="tabpanel" aria-labelledby="2008-tab">
                    <h2>2008 - Entrada do André Simões</h2>
                    <p class="aba">Segundo diretor da companhia.</p>
                  </div>
                  <div class="tab-pane fade" id="aba3" role="tabpanel" aria-labelledby="2011-tab">
                    <h2>2011 - Marca takao</h2>
                    <p class="aba">Ano do nascimento da marca.</p>
                  </div>
                  <div class="tab-pane fade" id="aba4" role="tabpanel" aria-labelledby="2019-tab">
                    <h2>2019 - POSICIONAMENTO DA MARCA</h2>
                    <p class="aba">Trabalho no desenvolvimento da empresa e seu posicionamento no mercado, desenvolvimento de novos projetos e desafios.</p>
                  </div>
                   <div class="tab-pane fade" id="aba5" role="tabpanel" aria-labelledby="2021-tab">
                    <h2>2021 - ENTRADA DO ANDRÉ NIERO</h2>
                    <p class="aba">Segundo CEO da companhia.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <h2 style="margin-top: 0">Produtos</h2>
            <h1>Goop Apresenta</h1>
          </div>
          <div class="col-xs-12 col-md-6 text-right">
            <img src="../assets/imgs/home/takao-goop.png" alt="Takao Goop" class="img-fluid" width="204">
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-xs-12 col-md-8 componente">
            <img src="../assets/imgs/home/goop-apresenta.jpg" class="img-fluid">
            <h3>peças para motor TAKAO,<br>vá mais longe com segurança.</h3>
          </div>
        </div>
      </div>

      <div class="container hide-md">
        <div class="row">
          <div class="col">
            <h1>No mercado desde os anos 2000</h1>
            <p>Nosso crescimento baseia-se em uma trajetória de coragem, vontade de estar sempre à frente e atenção as novas mudanças e tendências do cenário automotivo.</p>
            <h1>O que nos move vem do passado e direciona nosso futuro.</h1>
            <h2>2000 - Nasce a Goop</h2>
            <p>Nasce a Goop, fundada pela Fátima Soares a empresa surge em uma pequena sala dentro da retífica MM no bairro da Vila Guilherme, Zona Norte de São Paulo, aos poucos a empresa cresce e no ano seguinte muda para o bairro da Vila Maria.</p>
            <h2>2008 - Entrada do André Simões</h2>
            <p>Segundo diretor da companhia.</p>
            <h2>2011 - Marca takao</h2>
            <p>Ano do nascimento da marca.</p>
            <h2>2019 - POSICIONAMENTO DA MARCA</h2>
            <p>Trabalho no desenvolvimento da empresa e seu posicionamento no mercado, desenvolvimento de novos projetos e desafios.</p>
            <h2>2021 - ENTRADA DO ANDRÉ NIERO.</h2>
            <p>Segundo CEO da companhia.</p>
          </div>
        </div>
        <div class="row prod-mb">
          <div class="col-8 col-md-6">
            <h2 style="margin-top: 0">Produtos</h2>
            <h1>Goop Apresenta</h1>
          </div>
          <div class="col-4 col-md-6 text-right">
            <img src="../assets/imgs/home/takao-goop.png" alt="Takao Goop" class="img-fluid" width="204">
          </div>
          <div class="col col-md-8 componente">
            <img src="../assets/imgs/home/goop-apresenta.jpg" class="img-fluid">
            <h3>peças para motor TAKAO,<br>vá mais longe com segurança.</h3>
          </div>
        </div>
      </div>
    </section>
    <div class="motor d-flex align-items-center">
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="col-xs-12 col-md-8">
            <div class="align-self-center">
              <h3>Pensou motor pensou<br>TAKAO.</h3>
              <router-link to="/produtos" class="btn btn-azul">Saiba mais</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="unidades">
      <div class="container">
        <h3>Conheça nossas<br>unidades</h3>
        <router-link to="/unidades" class="btn btn-azul">Saiba mais</router-link>
      </div>
    </div>
    <div class="contato">
      <div class="container">
        <h3>Quer saber<br>mais?</h3>
        <router-link to="/fale-conosco" class="btn btn-azul">Fale Conosco</router-link>
      </div>
    </div>
    <!-- FOOTER -->
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import $ from 'jquery'

export default {
  name: 'Home',
  components: {
    Navbar,
    Footer
  },
  mounted() {
    $('#myTab a').on('click', function (event) {
      event.preventDefault();
      $(this).tab('show');
    })
  }
}


</script>
